var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useDataProvider, useTranslate, useNotify, useRefresh, } from 'react-admin';
import AllOrganizations from './AllOrganization';
import RevenueReport from './RevenueReport';
import YourDashboard from './YourDashborad';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
var Dashboard = function (props) {
    var translate = useTranslate();
    var notify = useNotify();
    var refresh = useRefresh();
    var _a = useState(true), showDashboard = _a[0], setShowDashboard = _a[1];
    var _b = useState(false), showAllOrganizations = _b[0], setShowAllOrganizations = _b[1];
    var _c = useState(false), showRevenueReport = _c[0], setShowRevenueReport = _c[1];
    var toggleDashboard = function () {
        setShowDashboard(!showDashboard);
    };
    var toggleAllOrganizations = function () {
        setShowAllOrganizations(!showAllOrganizations);
    };
    var toggleRevenueReport = function () {
        setShowRevenueReport(!showRevenueReport);
    };
    var _d = useState({
        "dashboard": {
            "view_general": false,
            "view_sales": false
        },
        "user": {
            "view_all": false,
            "view_org": false,
            "create_new": false,
            "edit_all": false,
            "edit_org": false,
            "disable_all": false
        },
        "organization": {
            "view_allOrg": false,
            "view_ownOrg": false,
            "create_org": false,
            "disable_allOrg": false,
            "disable_ownOrg": false,
            "edit_all": false,
            "edit_ownOrg": false,
            "edit_allGroup": false,
            "edit_ownGroup": false,
            "add_newGroup": false,
            "disable_allGroup": false,
            "disable_ownGroup": false,
            "disable_orgUser": false,
            "create_orgUser": false
        },
        "subject": {
            "view_all": false,
            "view_org": false,
            "create": false,
            "edit": false,
            "delete": false
        },
        "dataExport": {
            "export_all": false,
            "export_org": false,
            "export_retinADProbScores": false
        }
    }), accessDialogData = _d[0], setAccessDialogData = _d[1];
    var _e = useState(""), userOrg = _e[0], setUserOrg = _e[1];
    var dataProvider = useDataProvider();
    useEffect(function () {
        // const timer = setTimeout(() => {
        //     console.log("update")
        //     window.location.reload();
        // }, 20);
        var _a;
        // // Cleanup function to clear the timer
        // return () => clearTimeout(timer);
        var cachedAuth = (_a = JSON.parse(localStorage.getItem('auth'))) !== null && _a !== void 0 ? _a : {};
        if (cachedAuth.id) {
            dataProvider.getOneWithoutDataJSON("users", { id: cachedAuth.id })
                .then(function (data) {
                var _a, _b, _c, _d;
                setAccessDialogData(function (prevState) { var _a; return (__assign(__assign({}, prevState), (_a = data === null || data === void 0 ? void 0 : data.profile) === null || _a === void 0 ? void 0 : _a.ACL)); });
                setUserOrg((_b = (_a = data.profile) === null || _a === void 0 ? void 0 : _a.info) === null || _b === void 0 ? void 0 : _b['current_organization'][1]);
                localStorage.setItem("appBarOrg", (_d = (_c = data.profile) === null || _c === void 0 ? void 0 : _c.info) === null || _d === void 0 ? void 0 : _d['current_organization'][1]);
                refresh();
            })
                .catch(function (error) {
                console.error(error);
                notify(translate('error.ACL.getACLFailed'), {
                    type: 'error',
                });
            });
            dataProvider.ShowUrOrg({ id: cachedAuth.id })
                .then(function (data) {
                setUserOrg(data.data.profile.info.current_organization[1]);
            })
                .catch(function (error) {
                console.error(error);
                notify(translate('error.organization.getUserOrgFailed'), {
                    type: 'error',
                });
            });
            // dataProvider.ShowUrOrg({ id: cachedAuth.id })
            //     .then((data) => {
            //         setUserOrg(data.data.orgName);
            //     })
            //     .catch((error) => {
            //         console.error(error);
            //         notify(
            //             translate('error.organization.getUserOrgFailed'),
            //             {
            //                 type: 'error',
            //             }
            //         );
            //     });
            // window.location.reload();
        }
    }, []);
    var _f = useState(0), selectedTab = _f[0], setSelectedTab = _f[1];
    return (_jsxs(Box, { children: [_jsxs(Tabs, { value: selectedTab, onChange: function (event, newValue) { return setSelectedTab(newValue); }, sx: {
                    backgroundColor: "#1561a5",
                    borderRadius: '8px',
                    marginTop: '1.3rem',
                    marginLeft: '0.75em',
                    marginRight: '0.75em',
                }, TabIndicatorProps: {
                    style: {
                        backgroundColor: '#03a9f4',
                        height: 6,
                        borderRadius: '1.5px',
                    },
                }, children: [accessDialogData.dashboard.view_sales && (_jsx(Tab, { label: translate('pages.dashboard.title.revenueReport'), sx: {
                            color: 'white',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&.Mui-selected': {
                                color: 'white',
                            },
                        } })), accessDialogData.dashboard.view_general && (_jsx(Tab, { label: translate('pages.dashboard.title.allOrganizations'), sx: {
                            color: 'white',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&.Mui-selected': {
                                color: 'white',
                            },
                        } })), _jsx(Tab, { label: "".concat(translate('pages.dashboard.title.yourOrganization')), sx: {
                            color: 'white',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&.Mui-selected': {
                                color: 'white',
                            },
                        } })] }), _jsxs(Box, { sx: { mt: 2 }, children: [selectedTab === 0 && accessDialogData.dashboard.view_sales && _jsx(RevenueReport, {}), selectedTab === (accessDialogData.dashboard.view_sales ? 1 : 0) && accessDialogData.dashboard.view_general && _jsx(AllOrganizations, {}), selectedTab === (accessDialogData.dashboard.view_sales && accessDialogData.dashboard.view_general ? 2 :
                        accessDialogData.dashboard.view_sales || accessDialogData.dashboard.view_general ? 1 : 0) && _jsx(YourDashboard, {})] })] }));
};
export default Dashboard;
