var ACLData = {
    /*  dashboard: {
         "view-general": true,
         "view-sales": true,
     },
     user: {
         "view-all": true,
         "view-sales": true,
         "view-org": true,
         "view-new": true,
         "edit-all": true,
         "edit-org": true,
         "disable-all": true,
     },
     organization: {
         "view-allOrg": true,
         "view-ownOrg": true,
         "create-org": true,
         "disable-allOrg": true,
         "disable-ownOrg": true,
         "edit-all": true,
         "edit-ownOrg": true,
         "edit-allGroup": true,
         "edit-ownGroup": true,
         "add-new": true,
         "disable-allGroup": true,
         "disable-ownGroup": true,
         "disable-orgUser": true,
     },
     subject: {
         "view-all": true,
         "view-org": true,
         "create": true,
         "edit": true,
         "delete": true,
     },
     dataExport: {
         "export-all": true,
         "export-org": true,
         "export-retinADProbScores": true,
     },
     access: {
         Dashboard: {
             add: false, view: true, edit: false, delete: false
         },
         User: {
             add: true, view: true, edit: true, delete: true
         },
         UserGroup: {
             add: false, view: false, edit: false, delete: false
         },
         Subject: {
             add: true, view: true, edit: true, delete: true
         },
         DataExport: {
             add: false, view: true, edit: false, delete: false
         }
     } */
    dashboard: {
        view_general: true,
        view_sales: true,
    },
    user: {
        view_all: true,
        view_org: true,
        create_new: true,
        edit_all: true,
        edit_org: true,
        disable_all: true,
    },
    organization: {
        view_allOrg: true,
        view_ownOrg: true,
        create_org: true,
        disable_allOrg: true,
        disable_ownOrg: true,
        edit_all: true,
        edit_ownOrg: true,
        edit_allGroup: true,
        edit_ownGroup: true,
        add_newGroup: true,
        disable_allGroup: true,
        disable_ownGroup: true,
        disable_orgUser: true,
        create_orgUser: true,
    },
    subject: {
        view_all: true,
        view_org: true,
        create: true,
        edit: true,
        delete: true,
    },
    dataExport: {
        export_all: true,
        export_org: true,
        export_retinADProbScores: true,
    },
};
export { ACLData };
