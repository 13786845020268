var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDataProvider, useNotify, useRefresh, useTranslate } from 'react-admin';
import { Typography, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { general } from '../utils/componentWizard';
import { settingsStyles as styles } from './helper/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import SecurityIcon from '@mui/icons-material/Security';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ProfileComp from './components/Profile';
import LanguageComp from './components/Language';
import SecurityComp from './components/Security';
import MembershipComp from './components/Membership';
import PaymentComp from './components/Payment';
import { GenderMuiChoices } from '../subjectPage/Params';
import { LanguageChoices } from './Params';
import { BODY_GRID_PADDING, } from "../styles/styleConstants.data";
// individual panel component
var PanelComp = function (_a) {
    var icon = _a.icon, text = _a.text, handleOnclick = _a.handleOnclick, backgroundColor = _a.backgroundColor, name = _a.name;
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", padding: 0.5, style: { cursor: "pointer", backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : "white" }, onClick: function () { handleOnclick(name); }, children: [_jsx(Typography, { variant: "body", sx: { paddingRight: "0.5rem" }, children: icon }), _jsx(Typography, { variant: "body", children: text })] }));
};
var Settings = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    var _a = useState({
        gender: GenderMuiChoices,
        language: LanguageChoices
    }), params = _a[0], setParams = _a[1];
    var _b = useState([]), data = _b[0], setData = _b[1];
    // const resource = "dataexport";
    var panelCompNames = [{ name: "profile", label: translate("pages.settings.leftCol.profile"), icon: _jsx(AccountCircleIcon, {}) },
        { name: "language", label: translate("pages.settings.leftCol.language"), icon: _jsx(LanguageIcon, {}) },
        { name: "security", label: translate("pages.settings.leftCol.security"), icon: _jsx(SecurityIcon, {}) },
        { name: "membership", label: translate("pages.settings.leftCol.membership"), icon: _jsx(CardMembershipIcon, {}) },
        // { name: "payment", label: translate(`pages.settings.leftCol.payment`), icon: <CardMembershipIcon /> },
    ];
    var _c = useState({
        "dashboard": {
            "view_general": false,
            "view_sales": false
        },
        "user": {
            "view_all": false,
            "view_org": false,
            "create_new": false,
            "edit_all": false,
            "edit_org": false,
            "disable_all": false
        },
        "organization": {
            "view_allOrg": false,
            "view_ownOrg": false,
            "create_org": false,
            "disable_allOrg": false,
            "disable_ownOrg": false,
            "edit_all": false,
            "edit_ownOrg": false,
            "edit_allGroup": false,
            "edit_ownGroup": false,
            "add_newGroup": false,
            "disable_allGroup": false,
            "disable_ownGroup": false,
            "disable_orgUser": false,
            "create_orgUser": false
        },
        "subject": {
            "view_all": false,
            "view_org": false,
            "create": false,
            "edit": false,
            "delete": false
        },
        "dataExport": {
            "export_all": false,
            "export_org": false,
            "export_retinADProbScores": false
        }
    }), accessDialogData = _c[0], setAccessDialogData = _c[1];
    var _d = useState(true), isLoading = _d[0], setIsLoading = _d[1];
    var _e = useState("profile"), curSelectedComp = _e[0], setCurSelectedComp = _e[1]; //profile, language, security
    var _f = useState({}), originalInfo = _f[0], setOriginalInfo = _f[1];
    var _g = useState({
        profile: {
            info: {
                userId: '',
                userGroup: '',
                username: '',
                lastName: '',
                firstName: '',
                email: '',
                gender: '',
            },
            pw: {
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            },
            access: {
                Dashboard: {
                    add: false, view: true, edit: false, delete: false
                },
                User: {
                    add: true, view: true, edit: true, delete: true
                },
                UserGroup: {
                    add: false, view: false, edit: false, delete: false
                },
                Subject: {
                    add: true, view: true, edit: true, delete: true
                },
                DataExport: {
                    add: false, view: true, edit: false, delete: false
                }
            }
        },
        language: "English",
        security: false,
    }), filters = _g[0], setFilters = _g[1];
    useEffect(function () {
        var _a;
        var params = {};
        // const cachedAuth = localStorage.getItem('auth')
        var cachedAuth = (_a = JSON.parse(localStorage.getItem('auth'))) !== null && _a !== void 0 ? _a : {};
        if (cachedAuth) {
            console.log(data, '==log  cachedAuth ', cachedAuth);
            params.id = cachedAuth.id; //user id
            setFilters({});
            dataProvider.getOneWithoutDataJSON("users", params)
                .then(function (data) {
                var _a, _b, _c, _d;
                // console.log(data, '==log settings data 321')
                var newFilters = __assign({}, data);
                newFilters['profile']['info']['userId'] = cachedAuth.id;
                // newFilters['profile']['info']['username'] = cachedAuth.username;
                // newFilters['profile']['info']['email'] = cachedAuth.email;
                newFilters['profile']['pw'] = {
                    currentPassword: '',
                    newPassword: '',
                    confirmNewPassword: ''
                };
                setOriginalInfo(__assign({}, data.profile.info));
                newFilters['profile']['access'] = __assign({}, (_a = data === null || data === void 0 ? void 0 : data.profile) === null || _a === void 0 ? void 0 : _a.access);
                console.log("==log refresh", newFilters);
                setFilters(newFilters);
                setAccessDialogData(__assign(__assign({}, accessDialogData), (_b = data === null || data === void 0 ? void 0 : data.profile) === null || _b === void 0 ? void 0 : _b.ACL));
                refresh();
                localStorage.setItem("appBarOrg", (_d = (_c = data === null || data === void 0 ? void 0 : data.profile) === null || _c === void 0 ? void 0 : _c.info) === null || _d === void 0 ? void 0 : _d['current_organization'][1]);
                setIsLoading(false);
            }).catch(function (error) {
                // Handle any errors that occurred
                console.log(error, '==error');
                notify(translate('error.ACL.getACLFailed'), {
                    type: 'error',
                });
                setIsLoading(false);
                setAccessDialogData(__assign({}, accessDialogData));
            });
        }
    }, []);
    var handleSave = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var params = {
            data: {}
        };
        // if no password related or is other components, save directly
        if ((!((_a = filters.profile.pw) === null || _a === void 0 ? void 0 : _a.currentPassword) && !((_b = filters.profile.pw) === null || _b === void 0 ? void 0 : _b.newPassword) && !((_c = filters.profile.pw) === null || _c === void 0 ? void 0 : _c.confirmNewPassword)) ||
            curSelectedComp != "profile") {
            switch (curSelectedComp) {
                case "profile":
                    // check if original info is not same as filters
                    params.data = {
                        First_name: filters.profile.info.firstName,
                        gender: filters.profile.info.gender, Last_name: filters.profile.info.lastName,
                    };
                    if (originalInfo.email != filters.profile.info.email) {
                        params.data.email = filters.profile.info.email;
                    }
                    if (originalInfo.username != filters.profile.info.username) {
                        params.data.username = filters.profile.info.username;
                    }
                    break;
                case "language":
                    params.data['language'] = filters.language;
                    break;
                case "security":
                    params.data['twofa'] = (_d = filters.security) !== null && _d !== void 0 ? _d : false;
                    break;
            }
            dataProvider.updateOne("users", params, filters.profile.info.userId)
                .then(function (data) {
                notify(translate('pages.settings.notify.updated'));
                refresh();
                setIsLoading(false);
            }).catch(function (error) {
                // Handle any errors that occurred
                console.log(error, '==error');
                if (error == "HttpError2: This username or email is already in use.") {
                    notify(translate('error.settings.dupEmailUsn'), {
                        type: 'error',
                    });
                }
                else {
                    notify(translate('error.common.tryAgain'), {
                        type: 'error',
                    });
                }
                setIsLoading(false);
            });
        }
        // if have password, make some checkings
        else {
            switch (true) {
                // if current password and no new password
                case (filters.profile.pw.currentPassword && (!((_e = filters.profile.pw.newPassword) === null || _e === void 0 ? void 0 : _e.length) > 0 || !((_f = filters.profile.pw.confirmNewPassword) === null || _f === void 0 ? void 0 : _f.length) > 0)):
                    notify(translate('pages.settings.notify.newPW'));
                    break;
                // if no current password
                case (!filters.profile.pw.currentPassword && (((_g = filters.profile.pw.newPassword) === null || _g === void 0 ? void 0 : _g.length) > 0 || ((_h = filters.profile.pw.confirmNewPassword) === null || _h === void 0 ? void 0 : _h.length) > 0)):
                    notify(translate('pages.settings.notify.curPW'));
                    break;
                // else if have current password but new password not same as confirm new password
                case (((_j = filters.profile.pw.currentPassword) === null || _j === void 0 ? void 0 : _j.length) > 0 && (filters.profile.pw.newPassword != filters.profile.pw.confirmNewPassword)):
                    notify(translate('pages.settings.notify.newPWNotSame'), {
                        type: 'warning',
                    });
                    break;
                // update
                default:
                    // update both user info and password, 2 api
                    var cachedAuth = (_k = JSON.parse(localStorage.getItem('auth'))) !== null && _k !== void 0 ? _k : {};
                    // let cachedToken = JSON.parse(localStorage.getItem('accessToken')) ?? {}
                    var cachedToken = localStorage.getItem('accessToken');
                    var pwParams = {
                        data: __assign(__assign({}, filters.profile.pw), { token: cachedToken })
                    };
                    // check if original info is not same as filters
                    params.data = {
                        First_name: filters.profile.info.firstName,
                        gender: filters.profile.info.gender, Last_name: filters.profile.info.lastName,
                    };
                    if (originalInfo.email != filters.profile.info.email) {
                        params.data.email = filters.profile.info.email;
                    }
                    if (originalInfo.username != filters.profile.info.username) {
                        params.data.username = filters.profile.info.username;
                    }
                    console.log(pwParams, '==pwParams');
                    //change pw api, then update settings api
                    dataProvider.changePW(pwParams)
                        .then(function (data) {
                        return dataProvider.updateOne("users", params, filters.profile.info.userId);
                    }).then(function () {
                        refresh();
                        setIsLoading(false);
                        notify(translate('pages.settings.notify.updated'));
                    }).catch(function (error) {
                        // Handle any errors that occurred
                        // console.log(error, error.message, '==error includes',);
                        if (error == "HttpError2: Error changing password! Error message: Current password is incorrect" || error == "HttpError2: Current password is incorrect") {
                            notify(translate('error.settings.curPW'), {
                                type: 'error',
                            });
                        }
                        else if (error == "HttpError2: New passwords do not match") {
                            notify(translate('error.settings.newPW'), {
                                type: 'error',
                            });
                        }
                        else if (error == "HttpError2: Error changing password! Error message: Current password is incorrect") {
                            notify(translate('error.settings.curPW'), {
                                type: 'error',
                            });
                        }
                        else if (error == "HttpError2: User not found") {
                            notify(translate('error.settings.noUser'), {
                                type: 'error',
                            });
                        }
                        else {
                            notify(translate('error.settings.PW'), {
                                type: 'error',
                            });
                        }
                        setIsLoading(false);
                    });
            }
        }
    };
    return (_jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", style: styles.settingsContainer, padding: BODY_GRID_PADDING, children: [_jsxs(Grid, { item: true, xs: 12, children: [_jsx(general.breadcrumb, { trail: ["".concat(translate("pages.settings.name"))] }), _jsx(Grid, { item: true, display: "flex", alignItems: "center", justifyContent: "start", children: _jsx(Typography, { variant: "h5", sx: { paddingRight: '10px', fontWeight: 'bold' }, children: translate("pages.settings.name") }) })] }), _jsx(Grid, { item: true, lg: 1.5, md: 2, xs: 12, style: styles.componentContainer, children: panelCompNames.map(function (el, idx) {
                    var backgroundColor;
                    if (el.name === curSelectedComp) {
                        backgroundColor = "#F2F2F2";
                    }
                    return (_jsx(PanelComp, { icon: el.icon, text: el.label, name: el.name, backgroundColor: backgroundColor, handleOnclick: setCurSelectedComp }));
                }) }), _jsxs(Grid, { item: true, lg: 10, md: 9, xs: 12, style: styles.componentContainer, children: [_jsxs(Grid, { item: true, display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 2, children: [_jsx(Typography, { variant: "h4", sx: { paddingRight: '10px' }, children: translate("pages.settings.leftCol.".concat(curSelectedComp)) }), _jsxs(Typography, { variant: "body1", sx: {
                                    paddingRight: '10px',
                                    color: "#1976D2", // Blue color
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    display: "flex",
                                    alignItems: "center",
                                    transition: "all 0.3s ease",
                                    marginBottom: "1em", // Moved up a little bit
                                    '&:hover': {
                                        color: "#1565C0",
                                        transform: "scale(1.05)",
                                    }
                                }, onClick: handleSave, children: [_jsx(SaveIcon, { sx: {
                                            marginRight: '5px',
                                            transition: 'transform 0.3s ease',
                                            '&:hover': {
                                                transform: 'rotate(20deg)',
                                            },
                                        } }), translate("common.save")] })] }), curSelectedComp === "profile" && _jsx(ProfileComp, { params: params, translate: translate, filters: filters, setFilters: setFilters, accessDialogData: accessDialogData }), curSelectedComp === "language" && _jsx(LanguageComp, { params: params, translate: translate, filters: filters, setFilters: setFilters }), curSelectedComp === "security" && _jsx(SecurityComp, { params: params, translate: translate, filters: filters, setFilters: setFilters }), curSelectedComp === "membership" && _jsx(MembershipComp, { params: params, translate: translate, filters: filters, setFilters: setFilters }), curSelectedComp === "payment" && _jsx(PaymentComp, { params: params, translate: translate, filters: filters, setFilters: setFilters })] })] }));
};
export default Settings;
