var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import SignIn from './LoginPages/SignIn';
import ForgetPW from './LoginPages/ForgetPW';
import ResetPW from './LoginPages/ResetPW';
import LoginAuth from './LoginPages/LoginAuth';
import Register from './LoginPages/Register';
import packageJson from '../../package.json';
import { forgetPW, resetSuccess, loginWithTwofaChecking, loginSuccess, twofaTokenChecking, register, registerSuccess } from '../providers/authProvider';
import { Grid, Typography, } from '@mui/material';
import { Form, useTranslate, useLogin, useNotify, LocalesMenuButton, useDataProvider, useRefresh, } from 'react-admin';
import RegisterSuccess from './SuccessRegisterPage';
import AfterRegister from './AfterRegister';
var returnOTPDigit = function (value) {
    if (!isNaN(parseInt(value))) {
        return parseInt(value);
    }
    else {
        return '';
    }
};
var Login = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    //[back] [0906] resume below "signIn"
    var _b = useState("signIn"), curComp = _b[0], setCurComp = _b[1]; //component for -- page 1: signIn, loginAuth,forgetPW, resetPW || page 2: register
    var _c = useState({}), authData = _c[0], setAuthData = _c[1]; //save auth info from server
    var _d = useState(false), enableTwofaResend = _d[0], setEnableTwofaResend = _d[1]; //check if can reset 2fa - from countdown
    var _e = useState(""), serverOTP = _e[0], setServerOTP = _e[1]; //otp for user input
    var _f = useState(false), needTwofa = _f[0], setNeedTwofa = _f[1]; //check if need 2fa
    //sign in info
    var _g = useState({ username: "", password: "", email: "" }), signInInputs = _g[0], setSignInInputs = _g[1];
    // sign up info
    var _h = useState({ email: "", password: "", confirmPassword: "" }), signUpInputs = _h[0], setSignUpInputs = _h[1];
    // reset info
    var _j = useState({ resetPW: "", confirmResetPW: "", email: "", token: "" }), resetInputs = _j[0], setResetInputs = _j[1];
    var _k = useState(false), confirmForget = _k[0], setConfirmForget = _k[1]; //if clicked send email
    var translate = useTranslate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var login = useLogin();
    var refresh = useRefresh();
    var location = useLocation();
    var navigate = useNavigate();
    var AuthKey = 'auth';
    // document.body.style.zoom = "100%";
    useEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkParams()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, []);
    // first load, check params
    var checkParams = function () { return __awaiter(void 0, void 0, void 0, function () {
        var urlParams, type, token, email, _a, registerParams, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    urlParams = new URLSearchParams(window.location.search);
                    type = urlParams.get('type');
                    token = urlParams.get('token');
                    email = urlParams.get('email');
                    console.log(urlParams, '==urlParams', type, token);
                    if (!(type && token)) return [3 /*break*/, 6];
                    _a = true;
                    switch (_a) {
                        case (type == "register"): return [3 /*break*/, 1];
                        case (type == "setNewPW"): return [3 /*break*/, 5];
                    }
                    return [3 /*break*/, 6];
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    registerParams = { email: email, token: token };
                    return [4 /*yield*/, registerSuccess(registerParams)];
                case 2:
                    _b.sent();
                    setCurComp("registerSuccess");
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    if (error_1 == "Error: Failed to send email") {
                        notify(translate('error.login.failSendEmail'), {
                            type: 'error',
                        });
                    }
                    else if (error_1 == "Error: This email is already in use.") {
                        notify(translate('error.login.sameEmail'), {
                            type: 'error',
                        });
                    }
                    else {
                        notify(translate('error.login.sameEmail'), {
                            type: 'error',
                        });
                    }
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 6];
                case 5:
                    try {
                        setResetInputs(__assign(__assign({}, resetInputs), { email: email, token: token }));
                        setCurComp("resetPW");
                        return [3 /*break*/, 6];
                    }
                    catch (error) {
                        if (error == "Error: Failed to send email") {
                            notify(translate('error.login.failSendEmail'), {
                                type: 'error',
                            });
                        }
                        else if (error == "Error: This username or email is already in use.") {
                            notify(translate('error.login.allSame'), {
                                type: 'error',
                            });
                        }
                        else {
                            notify(translate('error.login.setNewPWFailed'), {
                                type: 'error',
                            });
                        }
                    }
                    _b.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); };
    // for sign in
    var handleSubmit = function (auth) { return __awaiter(void 0, void 0, void 0, function () {
        var loginData, lanSettings, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    return [4 /*yield*/, loginWithTwofaChecking(
                        // auth,
                        // location.state ? (location.state as any).nextPathname : '/'
                        {
                            username: signInInputs.username, password: signInInputs.password,
                        })
                        // if is twofa, set auth page
                    ];
                case 2:
                    loginData = _a.sent();
                    if (!((loginData === null || loginData === void 0 ? void 0 : loginData.message) && (loginData === null || loginData === void 0 ? void 0 : loginData.message) === 'Need 2FA')) return [3 /*break*/, 3];
                    setNeedTwofa(true);
                    setAuthData(loginData);
                    //setServerOTP(loginData.twofa);
                    setCurComp("loginAuth");
                    setLoading(false);
                    return [3 /*break*/, 5];
                case 3:
                    loginSuccess(loginData);
                    return [4 /*yield*/, getACL()];
                case 4:
                    lanSettings = _a.sent();
                    // set up defualt lanSettings
                    // const AuthKey = 'auth'
                    // let cachedAuth = JSON.parse(localStorage.getItem(AuthKey)) ?? {};
                    // if (cachedAuth) {
                    //     cachedAuth['language'] = lanSettings === 'English' ? "en" : "zh-HK";
                    //     // language: decodedToken.language ?? "en",
                    //     localStorage.setItem(AuthKey, JSON.stringify(cachedAuth));
                    //     localStorage.setItem("testlan", lanSettings);
                    // }
                    // window.location.reload();
                    setLoading(false);
                    // refresh();
                    navigate('/admin/dashboard');
                    _a.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    error_2 = _a.sent();
                    console.log(error_2, '==error');
                    setLoading(false);
                    if (error_2 == "Error: Invalid credentials.") {
                        notify(translate('error.login.invalidCredentials'), {
                            type: 'error',
                        });
                    }
                    return [2 /*return*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    // get ACL for org (top bar)
    var getACL = function () { return __awaiter(void 0, void 0, void 0, function () {
        var cachedAuth, settingsParams, rtnData, error_3;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    cachedAuth = (_a = JSON.parse(localStorage.getItem("auth"))) !== null && _a !== void 0 ? _a : {};
                    settingsParams = {};
                    if (!cachedAuth) return [3 /*break*/, 5];
                    settingsParams.id = cachedAuth.id;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, dataProvider.getOneWithoutDataJSON("users", settingsParams)];
                case 2:
                    rtnData = _d.sent();
                    localStorage.setItem("appBarOrg", (_c = (_b = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _b === void 0 ? void 0 : _b.info) === null || _c === void 0 ? void 0 : _c['current_organization'][1]);
                    // let cachedAuth = JSON.parse(localStorage.getItem(AuthKey)) ?? {};
                    // if (cachedAuth) {
                    cachedAuth['language'] = rtnData.language === 'English' ? "en" : "zh-HK";
                    // language: decodedToken.language ?? "en",
                    localStorage.setItem(AuthKey, JSON.stringify(cachedAuth));
                    localStorage.setItem("testlan", lanSettings);
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _d.sent();
                    console.error(error_3);
                    return [3 /*break*/, 4];
                case 4:
                    ;
                    _d.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // for forget password
    var handleForgetPW = function (auth) {
        setLoading(true);
        var params = { email: signInInputs.email };
        forgetPW(params)
            .then(function (data) {
            refresh();
            notify(translate("login.emailSent"));
            setLoading(false);
            setConfirmForget(true);
        }).catch(function (error) {
            setLoading(false);
            if (error == "Error: Bad Request" || error == "Error: This email does not belong to any users") {
                console.log("bad req", '==error');
                notify(translate('error.login.emailNotExist'), {
                    type: 'error',
                });
            }
            else if (error == "Error: Failed to send email") {
                notify(translate('error.login.failSendEmail'), {
                    type: 'error',
                });
            }
            else if (error == "Error: Please enter a valid email") {
                notify(translate('error.login.email'), {
                    type: 'error',
                });
            }
            else {
                notify(translate('error.login.emailFieldEmpty'), {
                    type: 'error',
                });
            }
        });
    };
    // for reset password
    var handleResetPW = function (auth) { return __awaiter(void 0, void 0, void 0, function () {
        var resetParams, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // if 2 new pw not same
                    if (resetInputs.resetPW != resetInputs.confirmResetPW) {
                        notify(translate('pages.settings.notify.newPWNotSame'), {
                            type: 'warning',
                        });
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    setLoading(true);
                    resetParams = { email: resetInputs.email, token: resetInputs.token, newPW: resetInputs.resetPW };
                    console.log(resetParams, '==resetParams');
                    return [4 /*yield*/, resetSuccess(resetParams)];
                case 2:
                    _a.sent();
                    notify(translate("login.notify.resetSuccess"));
                    setCurComp("signIn");
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _a.sent();
                    console.log(error_4, '==err');
                    notify(typeof error_4 === 'string'
                        ? error_4
                        : typeof error_4 === 'undefined' || !error_4.message
                            ? translate('error.login.tryAgain')
                            : error_4.message, {
                        type: 'error',
                    });
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // for twofa checking for login
    var handleTwofaLogin = function (isResend) { return __awaiter(void 0, void 0, void 0, function () {
        var params, rtnData, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    setLoading(true);
                    if (!isResend) return [3 /*break*/, 2];
                    notify(translate("login.notify.digitCode"));
                    return [4 /*yield*/, handleSubmit()];
                case 1:
                    _a.sent();
                    setLoading(false);
                    return [2 /*return*/];
                case 2:
                    params = { "username": authData.username, "token": serverOTP };
                    console.log('hih44 0');
                    return [4 /*yield*/, twofaTokenChecking(params)];
                case 3:
                    rtnData = _a.sent();
                    if ((rtnData === null || rtnData === void 0 ? void 0 : rtnData.message) == "Invalid token.") {
                        notify(translate("error.login.invalidToken"), {
                            type: 'error',
                        });
                        console.log('hih44 0 - 1', rtnData === null || rtnData === void 0 ? void 0 : rtnData.message);
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    else {
                        loginSuccess(rtnData);
                        //  // get ACL
                        // window.location.reload();
                        // 
                        //  // set up defualt lanSettings
                        // let lanSettings = await getACL();
                        // let cachedAuth = JSON.parse(localStorage.getItem(AuthKey)) ?? {};
                        // if (cachedAuth) {
                        //     cachedAuth['language'] = lanSettings === 'English' ? "en" : "zh-HK";
                        //     // language: decodedToken.language ?? "en",
                        //     localStorage.setItem(AuthKey, JSON.stringify(cachedAuth));
                        // }
                        setLoading(false);
                        // refresh()
                        navigate('/admin/dashboard');
                        // // setLoading(true);
                        // // setTimeout(() => {
                        // //     navigate('/admin/subjects');
                        // //     setLoading(false);
                        // // }, 3000);
                        console.log('hih44 1', AuthKey);
                    }
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_5 = _a.sent();
                    console.log(error_5, '==err');
                    notify(translate("error.login.invalidToken"), {
                        type: 'error',
                    });
                    console.log('hih44 3');
                    setLoading(false);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    //register
    var handleRegisterSubmit = function (auth) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, signUpParams, rtnData, error_6;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    console.log(auth, '==handleRegisterSubmit');
                    _a = true;
                    switch (_a) {
                        case (signUpInputs.password != signUpInputs.confirmPassword): return [3 /*break*/, 1];
                    }
                    return [3 /*break*/, 2];
                case 1:
                    notify(translate("error.login.PWNotSame"), {
                        type: 'error',
                    });
                    return [3 /*break*/, 6];
                case 2:
                    signUpParams = { "email": signUpInputs.email, "password": signUpInputs.password };
                    _b.label = 3;
                case 3:
                    _b.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, register(signUpParams)];
                case 4:
                    rtnData = _b.sent();
                    setCurComp("afterRegister");
                    return [3 /*break*/, 6];
                case 5:
                    error_6 = _b.sent();
                    if (error_6 == "Error: Error: email must be an email") {
                        notify(translate("error.login.email"), {
                            type: 'error',
                        });
                    }
                    else {
                        notify(translate("error.login.sameEmail"), {
                            type: 'error',
                        });
                    }
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs(Form, { onSubmit: handleSubmit, noValidate: true, children: [curComp === 'signIn' && _jsx(SignIn, { loading: loading, signInInputs: signInInputs, setSignInInputs: setSignInInputs, setCurComp: setCurComp }), curComp === 'forgetPW' && _jsx(ForgetPW, { notify: notify, loading: loading, signInInputs: signInInputs, setSignInInputs: setSignInInputs, setCurComp: setCurComp, handleForgetPW: handleForgetPW, setConfirmForget: setConfirmForget, confirmForget: confirmForget }), curComp === 'loginAuth' && _jsx(LoginAuth, { loading: loading, signInInputs: signInInputs, setCurComp: setCurComp, serverOTP: serverOTP, setServerOTP: setServerOTP, handleTwofaLogin: handleTwofaLogin, enableTwofaResend: enableTwofaResend, setEnableTwofaResend: setEnableTwofaResend }), curComp === 'resetPW' && _jsx(ResetPW, { notify: notify, loading: loading, signInInputs: resetInputs, setSignInInputs: setResetInputs, setCurComp: setCurComp, handleResetPW: handleResetPW })] }), _jsx(Form, { onSubmit: handleRegisterSubmit, noValidate: true, children: curComp === 'register' && _jsx(Register, { loading: loading, signUpInputs: signUpInputs, setSignUpInputs: setSignUpInputs, setCurComp: setCurComp }) }), curComp === "afterRegister" && _jsx(AfterRegister, { loading: loading, setCurComp: setCurComp }), curComp === 'registerSuccess' && _jsx(RegisterSuccess, { loading: loading, signInInputs: signInInputs, setSignInInputs: setSignInInputs, setCurComp: setCurComp }), _jsxs(Typography, { variant: "body2", color: "textSecondary", align: "center", style: { marginTop: '0.5em', fontWeight: 'bold' }, children: [translate('version'), ": ", packageJson.version] }), _jsx(Grid, { item: true, position: "absolute", bottom: 0, right: 0, children: _jsx(LocalesMenuButton, {}) })] }));
};
Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};
export default Login;
