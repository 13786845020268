var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDataProvider, useResourceContext, useNotify, useRefresh, useTranslate, } from "react-admin";
import { Divider, Typography, Grid, Button, } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { general } from "../utils/componentWizard";
import SelectedRowLeftColumn from "./SelectedRowLeftColumn";
import EditDialog from "./EditDialog";
import { dummyData } from "./helper/dummyData";
import TableDatagrid from "./TableDatagrid";
import FilterList from "./FilterList";
import { ACLData } from "../utils/dummyData";
import { GenderMuiChoices } from "../subjectPage/Params";
import { StatusMuiChoices } from "../examsPage/Params";
import { OrganizationMuiChoices, UserGroupMuiChoices } from "./helper/Params";
import { BODY_GRID_PADDING, NORMAL_GRID_SPACE, } from "../styles/styleConstants.data";
var UserList = function (props) {
    var location = useLocation();
    var navigate = useNavigate();
    var resource = useResourceContext(props);
    var attributeResource = "".concat(useResourceContext(props), "/userList");
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    var _a = useState(dummyData), data = _a[0], setData = _a[1]; //data table data
    // const resource = "UserList";
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(ACLData), acessControlList = _c[0], setAcessControlList = _c[1];
    var _d = useState({
    // analyzeFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // analyzeToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    }), filters = _d[0], setFilters = _d[1];
    var _e = useState({
        gender: GenderMuiChoices,
        organizations: OrganizationMuiChoices,
        userGroups: UserGroupMuiChoices,
        status: StatusMuiChoices,
    }), params = _e[0], setParams = _e[1]; //data table data
    var _f = useState(false), openLeftPanel = _f[0], setOpenLeftPanel = _f[1];
    var _g = useState({}), selectedRow = _g[0], setSelectedRow = _g[1];
    var _h = useState({}), selectedViewUser = _h[0], setSelectedViewUser = _h[1]; //when onclick the "eye" btn on list
    var _j = useState({
        dashboard: {
            view_general: false,
            view_sales: false,
        },
        user: {
            view_all: false,
            view_org: false,
            create_new: false,
            edit_all: false,
            edit_org: false,
            disable_all: false,
        },
        organization: {
            view_allOrg: false,
            view_ownOrg: false,
            create_org: false,
            disable_allOrg: false,
            disable_ownOrg: false,
            edit_all: false,
            edit_ownOrg: false,
            edit_allGroup: false,
            edit_ownGroup: false,
            add_newGroup: false,
            disable_allGroup: false,
            disable_ownGroup: false,
            disable_orgUser: false,
            create_orgUser: false,
        },
        subject: {
            view_all: false,
            view_org: false,
            create: false,
            edit: false,
            delete: false,
        },
        dataExport: {
            export_all: false,
            export_org: false,
            export_retinADProbScores: false,
        },
    }), accessDialogData = _j[0], setAccessDialogData = _j[1];
    var _k = useState({}), origDialogData = _k[0], setOrigDialogData = _k[1]; //original dialogData
    // dialog for add/edit user
    var _l = useState(false), dialogOpened = _l[0], setDialogOpened = _l[1];
    var _m = useState({}), dialogData = _m[0], setDialogData = _m[1];
    var _o = useState(null), selectedOrgId = _o[0], setSelectedOrgId = _o[1]; //this is to find which org id chose
    // const [selectedUserGroupIds, setSelectedUserGroupIds] = useState([]); //this is to handle "userGroup" vals after onchange
    // data table header
    var _p = useState([
        {
            id: translate("pages.subject.fields.id"),
            customId: translate("pages.subject.fields.customId"),
            firstname: translate("pages.subject.fields.firstname"),
            lastname: translate("pages.subject.fields.lastname"),
            gender: translate("pages.subject.fields.gender"),
            educationLevel: translate("pages.subject.fields.educationLevel"),
            dateOfBirth: translate("pages.subject.fields.dateOfBirth"),
            isHeader: true,
            selected: false,
        },
    ]), dataGridHeader = _p[0], setDataGridHeader = _p[1];
    var delAction = function (record) {
        var params = {
            data: {
                id: record.id,
                // customId: record.customId,  //[back] later switch to customId
                // firstname: record.firstname,
                // lastname: record.lastname,
                // gender: record.gender,
                // educationLevel: record.educationLevel,
                // dateOfBirth: record.dateOfBirth,
                // createby: 1,
                givenId: record.id, //[back] later switch to real id - id for db, not customId
            },
        };
        dataProvider.delete(resource, params).then(function () {
            refresh();
            notify("User deleted");
        });
    };
    var reloadRecord = function (values, dialogType) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedPassword, updatedConfirmpw, userGroups, userParams, newEditUserGroups, updatedData;
        return __generator(this, function (_a) {
            setIsLoading(true);
            try {
                console.log("==log submit from dialog", values, dialogType);
                updatedPassword = !values.password || values.password === "" ? null : values.password;
                updatedConfirmpw = !values.confirmpw || values.confirmpw === "" ? null : values.confirmpw;
                if (updatedPassword != updatedConfirmpw) {
                    notify("Password not the same");
                    return [2 /*return*/];
                }
                userGroups = params.userGroups;
                userParams = {
                    data: {
                        // // "id": values.id,
                        // "username": values.username,
                        Last_name: values.Last_name,
                        First_name: values.First_name,
                        // "email": values.email,
                        language: values.language,
                        gender: values.gender,
                        // "organizations": [values.organizations],
                        // "userGroups": [values.userGroups],
                        // "password": values.password
                    },
                };
                if (values.password) {
                    userParams.data.password = values.password;
                }
                if (values.username) {
                    userParams.data.username = values.username;
                }
                if (values.email) {
                    userParams.data.email = values.email;
                }
                if (values.editOrganizations) {
                    userParams.data.organizationIds = [values.editOrganizations];
                }
                if (values.editUserGroups) {
                    newEditUserGroups = userGroups.filter(function (item) {
                        return values.editUserGroups.includes(item.id);
                    });
                    userParams.data.userGroupIds = newEditUserGroups.map(function (item) { return item.id; });
                }
                console.log(data, "===data");
                switch (dialogType) {
                    case "Create":
                        updatedData = __spreadArray([], data, true);
                        updatedData.push(values);
                        setData(updatedData);
                        dataProvider
                            .create(attributeResource, userParams)
                            .then(function (returnData) {
                            refresh();
                            notify("User added");
                            setIsLoading(false);
                        })
                            .catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                            refresh();
                            notify("User create failed, please try another email or username");
                            setIsLoading(false);
                        });
                        break;
                    case "Edit":
                        userParams.data.givenId = values.id;
                        dataProvider
                            .updateOne(attributeResource, userParams, values.id)
                            .then(function () {
                            refresh();
                            notify("User updated");
                            setIsLoading(false);
                        })
                            .catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                            refresh();
                            notify("User update failed, please try another email or username");
                            setIsLoading(false);
                        });
                        break;
                }
                setDialogData(__assign({}, values));
                closeDialog();
            }
            catch (err) {
                console.log(err, "==err");
                refresh();
                notify("User update fail, please try another email or username");
                setIsLoading(false);
                // dispatch(actionTypes.SNACKBAR_ERROR);
            }
            return [2 /*return*/];
        });
    }); };
    var setDialogDataAction = function (record) {
        var _a, _b, _c, _d, _e;
        console.log(record, params, "==dialog data action");
        setDialogData(__assign({ submit: null, dialogType: (record === null || record === void 0 ? void 0 : record.id) ? "Edit" : "Create", id: (_a = record === null || record === void 0 ? void 0 : record.id) !== null && _a !== void 0 ? _a : null, editOrganizations: (_c = (_b = record === null || record === void 0 ? void 0 : record.current_organization) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : null, editUserGroups: (_e = (_d = record === null || record === void 0 ? void 0 : record.userGroups) === null || _d === void 0 ? void 0 : _d.map(function (group) { return group.id; })) !== null && _e !== void 0 ? _e : null }, record));
    };
    var openEditDialog = function (record) {
        var _a, _b, _c;
        //open dialog
        setDialogDataAction(record);
        console.log("==log record 444", record);
        var orgId = (_b = (_a = record === null || record === void 0 ? void 0 : record.current_organization) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null;
        var newParams = __assign({}, params);
        console.log(newParams, '==log newParams');
        //clear the stored from session storage
        newParams["userGroups"] = (_c = newParams["oriUserGroups"]) !== null && _c !== void 0 ? _c : [].filter(function (item) { return item.orgId === orgId; });
        setSelectedOrgId(orgId);
        setParams(newParams);
        // formik?.current?.resetForm();
        setDialogOpened(true);
    };
    var leftPanelOpenEditDialog = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        //open dialog
        console.log("==log record 9933", selectedRow.userGroups, (_a = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.userGroups) === null || _a === void 0 ? void 0 : _a.map(function (group) { return group.id; }));
        // setDialogDataAction(dialogData)
        setDialogData(__assign(__assign({ submit: null, dialogType: "Edit", id: (_b = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.id) !== null && _b !== void 0 ? _b : null }, selectedRow), { editOrganizations: (_d = (_c = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.current_organization) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null, editUserGroups: (_f = (_e = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.userGroups) === null || _e === void 0 ? void 0 : _e.map(function (group) { return group.id; })) !== null && _f !== void 0 ? _f : null }));
        var orgId = (_h = (_g = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.current_organization) === null || _g === void 0 ? void 0 : _g.id) !== null && _h !== void 0 ? _h : null;
        var newParams = __assign({}, params);
        console.log(newParams, '==log newParams');
        //clear the stored from session storage
        newParams["userGroups"] = (_j = newParams["oriUserGroups"]) !== null && _j !== void 0 ? _j : [].filter(function (item) { return item.orgId === orgId; });
        setSelectedOrgId(orgId);
        setParams(newParams);
        // formik?.current?.resetForm();
        setDialogOpened(true);
    };
    var closeDialog = function () {
        setDialogOpened(false);
    };
    var closeLeftPanel = function () {
        setSelectedRow({});
        setOpenLeftPanel(false);
    };
    //update usergroups params according to org
    useEffect(function () {
        if (selectedOrgId) {
            var newParams = __assign({}, params);
            //clear the stored from session storage
            newParams["userGroups"] = newParams["oriUserGroups"].filter(function (item) { return item.orgId === selectedOrgId; });
            // setSelectedUserGroupIds([]);
            console.log(selectedOrgId, "==selectedOrgId");
            setParams(newParams);
        }
    }, [selectedOrgId]);
    useEffect(function () {
        refresh();
    }, [filters]);
    useEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        var newFilters, sessonStorageJSON, cachedAuth, settingsParams, newParams, orgParams, orgData, userGroupData, usersData, err_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _e.trys.push([0, 4, , 5]);
                    newFilters = __assign({}, filters);
                    //if is redirected with userId, set in filter
                    if (location.state) {
                        newFilters.id = location.state.userId;
                    }
                    sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
                    if (sessonStorageJSON) {
                        setFilters(__assign(__assign({}, sessonStorageJSON), newFilters));
                    }
                    else {
                        setFilters(__assign({}, newFilters));
                    }
                    sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(__assign(__assign({}, sessonStorageJSON), newFilters)));
                    refresh();
                    searchFilterGroup();
                    cachedAuth = (_b = JSON.parse(localStorage.getItem("auth"))) !== null && _b !== void 0 ? _b : {};
                    settingsParams = {};
                    if (cachedAuth) {
                        settingsParams.id = cachedAuth.id;
                        dataProvider
                            .getOneWithoutDataJSON("users", settingsParams)
                            .then(function (rtnData) {
                            var _a;
                            var newACList = ((_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.ACL) || {};
                            setAcessControlList(newACList);
                            console.log("ssssssssss22ss", newACList);
                        })
                            .catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                        });
                    }
                    newParams = __assign({}, params);
                    orgParams = {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "id", order: "ASC" },
                        // filter: updatedFilters //[back][0715] resume this
                        filter: {},
                    };
                    return [4 /*yield*/, dataProvider.getListNoFilter("organizations", orgParams)];
                case 1:
                    orgData = _e.sent();
                    orgData = (_c = orgData === null || orgData === void 0 ? void 0 : orgData.data) === null || _c === void 0 ? void 0 : _c.map(function (item) { return ({
                        id: item.id,
                        name: item.orgName,
                        value: item.id,
                    }); });
                    return [4 /*yield*/, dataProvider.getListNoFilter("userGroup", orgParams)];
                case 2:
                    userGroupData = _e.sent();
                    console.log(orgData, "==orgData rr", userGroupData);
                    userGroupData = (_d = userGroupData === null || userGroupData === void 0 ? void 0 : userGroupData.data) === null || _d === void 0 ? void 0 : _d.map(function (item) { return ({
                        id: item.id,
                        name: item.name,
                        value: item.id,
                        orgId: item.orgId,
                    }); });
                    newParams["organizations"] = orgData;
                    newParams["userGroups"] = [];
                    newParams["oriUserGroups"] = userGroupData;
                    setParams(newParams);
                    return [4 /*yield*/, dataProvider.getListNoFilter("users", orgParams)];
                case 3:
                    usersData = _e.sent();
                    console.log(usersData, "==usersData");
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _e.sent();
                    console.log(err_1, "==err");
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, []);
    var getCurrentViewingUser = function (userId) {
        var settingsParams = { id: userId };
        dataProvider
            .getOneWithoutDataJSON("users", settingsParams)
            .then(function (rtnData) {
            var _a, _b;
            // console.log(userId, { ...ACLData,...rtnData.profile.access }, rtnData.profile.access, '==hff')
            var rtnACL = __assign({}, (_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.access);
            console.log(ACLData.access, "==ff", rtnACL);
            setSelectedViewUser(__assign(__assign({}, ACLData.access), rtnACL));
            setAccessDialogData(__assign(__assign({}, accessDialogData), (_b = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _b === void 0 ? void 0 : _b.ACL));
            console.log("==hhh8", rtnData, __assign(__assign({}, ACLData.access), rtnACL));
            setOpenLeftPanel(true);
        })
            .catch(function (error) {
            // Handle any errors that occurred
            setSelectedViewUser(__assign({}, ACLData.access)); //[back] [0815] remove this
            setAccessDialogData(__assign({}, accessDialogData));
            console.error(error);
        });
    };
    var searchFilterGroup = function () {
        var _a;
        setIsLoading(true);
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        // update the filter labels
        // let updatedFilters = JSON.parse(JSON.stringify(filters));
        // if (updatedFilters?.educationLevel) {
        //     updatedFilters.educationLevel = EducationLevelMuiChoices[filters.educationLevel].label
        // }
        // if (updatedFilters?.gender) {
        //     updatedFilters.gender = GenderMuiChoices[filters.gender].label
        // }
        var params = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "id", order: "ASC" },
            // filter: updatedFilters //[back][0715] resume this
            filter: __assign(__assign({}, filters), sessonStorageJSON),
        };
        console.log("==log params on subject list", params);
        dataProvider
            .getList(resource, params)
            .then(function (data) {
            refresh();
            notify("Users fetched");
            setIsLoading(false);
        })
            .catch(function (error) {
            // Handle any errors that occurred
            console.error(error);
            setIsLoading(false);
        });
    };
    return (_jsxs(Grid, { container: true, padding: BODY_GRID_PADDING, columnGap: NORMAL_GRID_SPACE, rowGap: 2, direction: { xs: "row", lg: "column" }, children: [openLeftPanel && (_jsx(Grid, { xs: 12, lg: 3, children: _jsx(SelectedRowLeftColumn, { acessControlList: acessControlList, translate: translate, selectedRow: selectedRow, setSelectedRow: setSelectedRow, openEditDialog: leftPanelOpenEditDialog, setOpenLeftPanel: setOpenLeftPanel, closeLeftPanel: closeLeftPanel, selectedViewUser: selectedViewUser, accessDialogData: accessDialogData, selectedOrgId: selectedOrgId, setSelectedOrgId: setSelectedOrgId, params: params }) })), _jsxs(Grid, { container: true, xs: 12, lg: openLeftPanel ? 9 : 12, alignContent: "start", justifyItems: "baseline", direction: "row", rowGap: 1, children: [_jsx(Grid, { xs: 12, children: _jsx(general.breadcrumb, { trail: [
                                translate("breadcrumb.userManagement"),
                                "".concat(translate("pages.userList.name")),
                            ] }) }), _jsxs(Grid, { xs: 12, display: "flex", alignItems: "center", justifyContent: "start", direction: "row", columnGap: NORMAL_GRID_SPACE, children: [_jsx(Grid, { children: _jsx(Typography, { className: "normal-text large-bold", children: translate("pages.userList.listName") }) }), (acessControlList.user.create_new) && (_jsx(Grid, { children: _jsx(Button, { variant: "contained", startIcon: _jsx(AddCircleOutlineIcon, {}), onClick: openEditDialog, children: translate("pages.userList.addNew") }) }))] }), _jsx(Grid, { xs: 12, children: _jsx(Divider, {}) }), _jsx(FilterList, { translate: translate, search: searchFilterGroup, isLoading: isLoading, params: params, filters: filters, setFilters: setFilters }), _jsx(TableDatagrid, { props: resource, data: data, setData: setData, setOrigDialogData: setOrigDialogData, translate: translate, setOpenLeftPanel: setOpenLeftPanel, getCurrentViewingUser: getCurrentViewingUser, acessControlList: acessControlList, selectedRow: selectedRow, setSelectedRow: setSelectedRow, openEditDialog: openEditDialog, filters: filters, setFilters: setFilters, isLoading: isLoading, setIsLoading: setIsLoading, delAction: delAction })] }), dialogOpened && (_jsx(EditDialog, { opened: dialogOpened, data: dialogData, closeDialog: closeDialog, reloadRecord: reloadRecord, translate: translate, params: params, selectedOrgId: selectedOrgId, setSelectedOrgId: setSelectedOrgId }))] }));
};
export default UserList;
