var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import * as React from "react";
import { useState } from "react";
import { Grid, Typography, Dialog, DialogActions, DialogContent, Zoom, } from "@mui/material";
import { Formik } from "formik";
// import { useDispatch } from 'react-redux';
// import API from '../../../api';
// import * as actionTypes from '../../../store/actions';
import { dialog } from "../utils/componentWizard";
import { Form as RAForm, DateInput as RADateInput, minValue as RAminValue, useNotify, } from "react-admin";
import { getTodaysDateChinaTimeZone } from "../utils/datePickerTimeFormat";
import { POPUP_FIELD_SIZE, POPUP_INPUT_SIZE } from "../styles/popup/popup.data";
import popupStyles from "../styles/popup/popup.module.css";
var classes = {
    noMarginDialog: {
        "&>div:nth-child(3)": {
            "&>div": {
                margin: 0,
            },
        },
    },
};
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Zoom, __assign({ ref: ref }, props));
});
var EditDialog = function (_a) {
    var translate = _a.translate, opened = _a.opened, data = _a.data, 
    // openDialog,
    closeDialog = _a.closeDialog, reloadRecord = _a.reloadRecord;
    var notify = useNotify();
    var _b = useState(getTodaysDateChinaTimeZone()), exam_date = _b[0], set_exam_date = _b[1];
    var _c = useState("submit"), submitType = _c[0], setSubmitType = _c[1];
    console.log("==log data", data);
    var valuesChecking = function (givenValues) {
        var hasAllVals = 
        // givenValues.ai_model &&
        givenValues.eye;
        return hasAllVals;
    };
    return (_jsx(React.Fragment, { children: _jsxs(Dialog, { open: opened, TransitionComponent: Transition, onClose: closeDialog, className: classes.noMarginDialog, keepMounted: true, sx: {
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                "& .MuiDialog-paper": {
                    borderRadius: "30px",
                    backgroundColor: "#F5F5F5",
                },
            }, children: [_jsx(dialog.header, { title: data.dialogType === "Edit"
                        ? "".concat(translate("pages.exam.dialog.edit_exam"))
                        : "".concat(translate("pages.exam.dialog.add_new_exam")), handleClose: closeDialog }), _jsx(Formik, { enableReinitialize: true, initialValues: data, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            try {
                                console.log(submitType, "==submitType");
                                reloadRecord(__assign(__assign({}, values), { exam_date: new Date(exam_date) }), data === null || data === void 0 ? void 0 : data.dialogType, submitType);
                                closeDialog();
                            }
                            catch (err) {
                                console.log("==err", err);
                                // dispatch(actionTypes.SNACKBAR_ERROR);
                            }
                            return [2 /*return*/];
                        });
                    }); }, children: function (props) {
                        var values = props.values, handleSubmit = props.handleSubmit;
                        console.log("==log editing", values);
                        return (_jsxs("form", { noValidate: true, onSubmit: handleSubmit, children: [_jsxs(DialogContent, { className: popupStyles.dialogContent, children: [_jsxs(Grid, { container: true, className: popupStyles.container, display: "flex", flexWrap: "wrap", children: [_jsxs(Grid, { item: true, xs: 12, display: "none", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: POPUP_FIELD_SIZE, children: _jsx(Typography, { className: "".concat(popupStyles.field, " small-text large-bold"), padding: 2, children: translate("pages.exam.fields.subject_id") }) }), _jsxs(Grid, { xs: POPUP_INPUT_SIZE, children: [console.log("==props", props), _jsx(dialog.textField, { form: props, filters: [], setFilters: function () { }, label: "", name: "hkid_id", fullWidth: true, disabled: true })] })] }), data.dialogType === "Edit" && (_jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: POPUP_FIELD_SIZE, children: _jsx(Typography, { className: "".concat(popupStyles.field, " small-text large-bold"), padding: 2, children: translate("pages.exam.fields.id") }) }), _jsx(Grid, { xs: POPUP_INPUT_SIZE, children: _jsx(dialog.textField, { form: props, filters: [], setFilters: function () { }, label: "", name: "id", fullWidth: true, disabled: true }) })] })), _jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: POPUP_FIELD_SIZE, children: _jsxs(Typography, { className: "".concat(popupStyles.field, " small-text large-bold"), padding: 2, children: [translate("pages.exam.fields.exam_date"), "*"] }) }), _jsx(Grid, { xs: POPUP_INPUT_SIZE, children: _jsx(RAForm, { children: _jsx(RADateInput, { source: "subject_date_of_birth", label: false, validate: RAminValue(new Date("2024-06-06")), onChange: function (e) {
                                                                        set_exam_date(e.target.value);
                                                                    }, fullWidth: true, sx: {
                                                                        "& .MuiInputBase-root": {
                                                                            borderRadius: "10px",
                                                                            boxShadow: "0 0 5px gray",
                                                                            overflow: "auto",
                                                                        },
                                                                        "& .MuiFormLabel-root": {},
                                                                    }, variant: "outlined", defaultValue: exam_date ? new Date(exam_date) : new Date() }) }) })] }), data.dialogType === "Edit" && (_jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: 5 }), _jsx(Grid, { xs: 7 })] }))] }), _jsxs(Grid, { item: true, xs: 12, marginY: 1, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [data.dialogType === "Edit" && (_jsx("div", { style: {
                                                        flex: "1 1 50%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }, onClick: function () { return setSubmitType("submit"); }, children: _jsx(dialog.submitButton, { isSubmitting: props.isSubmitting, text: translate("dialog.submit") }) })), data.dialogType === "Create" && (_jsx("div", { style: {
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                    }, onClick: function () { return setSubmitType("submitWithImport"); }, children: _jsx(dialog.submitButton, { isSubmitting: props.isSubmitting, text: translate("dialog.submit_and_import") }) }))] })] }), _jsx(DialogActions, {})] }));
                    } })] }) }));
};
export default EditDialog;
