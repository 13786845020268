var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { Grid, Typography, Button } from '@mui/material';
import { Document, Page, pdfjs } from "react-pdf";
import { blobToURL } from "../fileUploadComp";
import Drop from './Drop';
pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/".concat(pdfjs.version, "/pdf.worker.js");
var downloadURI = function (uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
var styles = {
    documentBlock: {
        width: 160,
        height: 200,
        margin: "20px auto",
        marginTop: 8,
        overflow: "hidden"
    },
    controls: {
        maxWidth: 160,
        margin: "0 auto",
        marginTop: 8,
    },
    container: {
        width: 200,
        backgroundColor: "#E7E7E7"
    }
};
var FileUpload = function () {
    var _a = useState(null), pdf = _a[0], setPdf = _a[1];
    var _b = useState(null), fileInfos = _b[0], setFileInfos = _b[1];
    var _c = useState(null), pageDetails = _c[0], setPageDetails = _c[1];
    var documentRef = useRef(null);
    return (_jsxs(Grid, { item: true, style: styles.container, children: [!pdf &&
                _jsx(Drop, { onLoaded: function (files) { return __awaiter(void 0, void 0, void 0, function () {
                        var URL;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, blobToURL(files[0])];
                                case 1:
                                    URL = _a.sent();
                                    setFileInfos(files[0]);
                                    setPdf(URL);
                                    console.log('==log URL', URL, files[0]);
                                    return [2 /*return*/];
                            }
                        });
                    }); } }), pdf && _jsxs(Grid, { item: true, children: [_jsx(Grid, { item: true, ref: documentRef, style: styles.documentBlock, children: _jsx(Document, { file: pdf, onLoadSuccess: function (data) {
                            }, children: _jsx(Page, { pageNumber: 1, width: 160, height: 200, onLoadSuccess: function (data) {
                                    setPageDetails(data);
                                } }) }) }), _jsxs(Grid, { item: true, paddingX: 2, children: [_jsx(Typography, { variant: "body", children: fileInfos === null || fileInfos === void 0 ? void 0 : fileInfos.name }), _jsx(Button, { body: "variant", style: { color: "red" }, onClick: function () {
                                    setPdf(null);
                                    setPageDetails(null);
                                }, children: "X" })] })] })] }));
};
export default FileUpload;
