var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, Grid, } from "@mui/material";
import { rightPanelstyles as styles } from "./helper/styles";
import { ExportTextCheckBox, ExportDateCheckBox } from "./helper/CheckBoxComp";
import { styled } from "@mui/material/styles";
var StyledTypography = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        paddingRight: "10px",
        color: "#1976D2",
        cursor: "pointer",
        fontWeight: "bold",
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        transition: "all 0.3s ease",
        position: "relative",
        "&:hover": {
            color: "#1565C0",
        },
        "&::before": {
            content: '""',
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "0",
            height: "0",
            backgroundColor: "rgba(25, 118, 210, 0.1)",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
            transition: "width 0.3s ease, height 0.3s ease",
        },
        "&:hover::before": {
            width: "150%",
            height: "150%",
        },
    });
});
var RightPanel = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var acessControlList = _a.acessControlList, isLoading = _a.isLoading, translate = _a.translate, exportFields = _a.exportFields, setExportFields = _a.setExportFields, exportDateInfoFields = _a.exportDateInfoFields, setExportDateInfoFields = _a.setExportDateInfoFields;
    var handleSelectAll = function () {
        // make all key true in "exportFields"
        var updatedExportFields = JSON.parse(JSON.stringify(exportFields));
        // const exceptions = ['examDate', 'analyzeDate'];
        function setAllValuesToTrue(obj) {
            for (var key in obj) {
                // below is select all
                if (key === "examDate" || key === "analyzeDate") {
                    return false; // Ignoring examDate and analyzeDate
                }
                else if (typeof obj[key] === "object") {
                    setAllValuesToTrue(obj[key]); // Recursively handle nested objects
                }
                else {
                    obj[key] = true;
                }
            }
        }
        setAllValuesToTrue(updatedExportFields);
        setExportFields(updatedExportFields);
        // return updatedExportFields;
    };
    var handleReset = function (criteria) {
        // if is criteria, only set date to false
        // setExportFields({});
        var updatedExportFields = JSON.parse(JSON.stringify(exportFields));
        var exceptions = ["examDate", "analyzeDate"];
        if (criteria) {
            updatedExportFields["examDate"] = false;
            updatedExportFields["analyzeDate"] = false;
        }
        else {
            // Define keys to exclude from being set to true
            function setAllValuesToFalseExcept(obj, exceptions) {
                for (var key in obj) {
                    if (typeof obj[key] === "object") {
                        setAllValuesToFalseExcept(obj[key], exceptions); // Recursively handle nested objects
                    }
                    else if (!exceptions.includes(key)) {
                        obj[key] = false;
                    }
                }
            }
            // Call the function to update all values to true except for 'examDate' and 'analyzeDate'
            setAllValuesToFalseExcept(updatedExportFields, exceptions);
        }
        setExportFields(updatedExportFields);
    };
    var handleChildCheckBox = function (checked, name, checkBoxType) {
        var updatedExportFields = JSON.parse(JSON.stringify(exportFields));
        updatedExportFields[name] = checked;
        setExportFields(updatedExportFields);
    };
    var handleParentCheckBox = function (checked, name, checkBoxType) {
        var updatedExportFields = JSON.parse(JSON.stringify(exportFields));
        // loop through whole child
        var childKeys = Object.keys(updatedExportFields[name]);
        childKeys.map(function (el, idx) {
            updatedExportFields[name][el] = checked;
        });
        setExportFields(updatedExportFields);
    };
    var handleCheckBox = function (checked, name, checkBoxType) {
        var updatedExportFields = JSON.parse(JSON.stringify(exportFields));
        var splitNames = name.split("_");
        updatedExportFields[splitNames[0]][splitNames[1]] = checked;
        setExportFields(updatedExportFields);
    };
    var handleChangeDate = function (value, checkBoxName, dateName) {
        var updatedExportDateInfoFields = __assign({}, exportDateInfoFields);
        updatedExportDateInfoFields[dateName] = value;
        setExportDateInfoFields(updatedExportDateInfoFields);
    };
    return (_jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", children: [_jsxs(Grid, { item: true, style: styles.dataExportContainer, children: [_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", children: [_jsx(Grid, { className: "normal-text", children: translate("pages.dataExport.name") }), _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", children: [_jsx(StyledTypography, { onClick: handleSelectAll, children: translate("pages.common.selection.select_all") }), _jsx(StyledTypography, { paddingX: 3, onClick: function () {
                                            handleReset();
                                        }, children: translate("pages.common.selection.reset") })] })] }), _jsx(ExportTextCheckBox, { name: "subjectInformation", label: translate("pages.dataExport.fields.subject_information"), 
                        // checkBoxType="child"
                        checked: exportFields.subjectInformation, handleChange: handleChildCheckBox }), _jsx(ExportTextCheckBox, { name: "images", label: translate("pages.dataExport.fields.images"), checked: exportFields.images.fp || exportFields.images.oct, handleChange: handleParentCheckBox }), _jsx(ExportTextCheckBox, { name: "images_fp", label: translate("pages.dataExport.fields.fps"), checkBoxType: "child", checked: exportFields.images.fp, handleChange: handleCheckBox }), _jsx(ExportTextCheckBox, { name: "images_oct", label: translate("pages.dataExport.fields.oct_reports"), checkBoxType: "child", checked: exportFields.images.oct, handleChange: handleCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADReports", label: translate("pages.dataExport.fields.retinAD_reports"), checked: exportFields.retinADReports.fp ||
                            exportFields.retinADReports.oct ||
                            exportFields.retinADReports.fpAndoct, handleChange: handleParentCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADReports_fp", label: translate("pages.dataExport.fields.fp"), checkBoxType: "child", checked: exportFields.retinADReports.fp, handleChange: handleCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADReports_oct", label: translate("pages.dataExport.fields.oct"), checkBoxType: "child", checked: exportFields.retinADReports.oct, handleChange: handleCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADReports_fpAndoct", label: translate("pages.dataExport.fields.fp_oct"), checkBoxType: "child", checked: exportFields.retinADReports.fpAndoct, handleChange: handleCheckBox }), acessControlList.dataExport.export_retinADProbScores && (_jsxs(_Fragment, { children: [_jsx(ExportTextCheckBox, { name: "retinADProbabilityScores", label: translate("pages.dataExport.fields.retinAD_probability_scores"), labelRed: translate("pages.dataExport.fields.internal_use"), checked: ((_b = exportFields.retinADProbabilityScores) === null || _b === void 0 ? void 0 : _b.fp) ||
                                    ((_c = exportFields.retinADProbabilityScores) === null || _c === void 0 ? void 0 : _c.oct) ||
                                    ((_d = exportFields.retinADProbabilityScores) === null || _d === void 0 ? void 0 : _d.fpAndoct), handleChange: handleParentCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADProbabilityScores_fp", label: translate("pages.dataExport.fields.fp"), checkBoxType: "child", checked: (_e = exportFields.retinADProbabilityScores) === null || _e === void 0 ? void 0 : _e.fp, handleChange: handleCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADProbabilityScores_oct", label: translate("pages.dataExport.fields.oct"), checkBoxType: "child", checked: (_f = exportFields.retinADProbabilityScores) === null || _f === void 0 ? void 0 : _f.oct, handleChange: handleCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADProbabilityScores_fpAndoct", label: translate("pages.dataExport.fields.fp_oct"), checkBoxType: "child", checked: (_g = exportFields.retinADProbabilityScores) === null || _g === void 0 ? void 0 : _g.fpAndoct, handleChange: handleCheckBox })] })), _jsx(ExportTextCheckBox, { name: "questionnaireResults", label: translate("pages.dataExport.fields.questionnaire_results"), checked: exportFields.questionnaireResults, handleChange: handleChildCheckBox })] }), _jsxs(Grid, { container: true, style: styles.dataExportContainer, children: [_jsxs(Grid, { container: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", children: [_jsx(Grid, { className: "normal-text", children: translate("pages.dataExport.criteria") }), _jsx(Grid, { className: "normal-text", display: "flex", flexWrap: "wrap", children: _jsx(StyledTypography, { paddingX: 3, onClick: function () {
                                        handleReset("criteria");
                                    }, children: translate("pages.common.selection.reset") }) })] }), _jsxs(Grid, { container: true, xs: 12, children: [_jsx(Grid, { xs: 12, children: _jsx(ExportDateCheckBox, { name: "examDate", dateFromName: "examFromDate", dateToName: "examToDate", label: translate("pages.dataExport.fields.examDate"), dateFromLabel: translate("pages.dataExport.fields.dateFrom"), dateToLabel: translate("pages.dataExport.fields.dateTo"), checked: exportFields.examDate, handleChange: handleChildCheckBox, handleChangeDate: handleChangeDate }) }), _jsx(Grid, { xs: 12, children: _jsx(ExportDateCheckBox, { name: "analyzeDate", dateFromName: "analyzeFromDate", dateToName: "analyzeToDate", label: translate("pages.dataExport.fields.analyzeDate"), dateFromLabel: translate("pages.dataExport.fields.dateFrom"), dateToLabel: translate("pages.dataExport.fields.dateTo"), checked: exportFields.analyzeDate, handleChange: handleChildCheckBox, handleChangeDate: handleChangeDate }) })] })] })] }));
};
export default RightPanel;
